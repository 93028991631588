:root {
    --primary-color: #29488d;
    --secondary-color: #f4f4f4;
    --text-color: #394b5c;
    --light-color: #fff;
    --gray-color: #f4f7f8;
    --gray-color2: #e6ebec;
    --danger-color: red;
    --success-color: #1eb53a;
    --warning-color: #FBE526;
    --space-0: 0;
    --space-1: 5px;
    --space-2: 15px;
    --space-3: 30px;
    --space-4: 60px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Rubik', sans-serif;
}

.body {
    min-height: 60vh;
    background-color: #F4F7F8;
}

.main {
    background-color: var(--gray-color);
    min-height: 70vh;
    padding-top: var(--space-2);
}

#map {
    height: 180px;
}

.container {
    max-width: 1206px !important;

}

header {
    background-color: var(--light-color);
}

/* TEXT STYLES */
.text {
    font-size: 18px;
    line-height: 27px;
    color: var(--text-color);
}

.title {
    position: absolute;
    top: 20px;
    right: 0;
}

.text-title {
    font-size: 30px;
    line-height: 45px;
    margin: var(--space-2) 0;
    color: var(--text-color);
    font-weight: bold;
}

.text-medium {
    font-family: "rubik-medium", sans-serif !important;
    font-size: 13px;
    font-weight: bold;
    line-height: 27px;
    color: var(--text-color);

}

.text-xs {
    font-size: 12px;
    color: #acacac;
    line-height: 18px;
}

.text-sm {
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
}

.text-md {
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    color: var(--text-color);
    text-align: center;
}

.text-lg {
    font-size: 22px;
    color: var(--text-color);
    line-height: 33px;
}

.text-xl {
    font-size: 30px;
    line-height: 45px;
    font-weight: bold;
    color: var(--text-color);
}

.text-through {
    text-decoration: line-through;
}


/* BUTTONS */

.small-span-button {
    font-size: 12px;
    color: var(--light-color);
    padding: 7px 15px;
    border-radius: var(--space-2);
    font-weight: 500;
    margin-right: var(--space-1);
}


.new {
    background-color: var(--success-color);
}

.discount {
    background-color: var(--warning-color);
    color: #394b5c;
}

.trend {
    background-color: var(--primary-color);
}

.sale {
    background-color: var(--warning-color);
    color: black;
}

.show-all-button {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    padding: var(--space-2) 0;
    transition: 0.37s;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    border-radius: var(--space-2);
    color: var(--primary-color);
    width: 169px;
}

.show-all-button:hover {
    background-color: var(--primary-color);
    color: var(--light-color);
}

/* FONT WEIGHT */

.bold {
    font-weight: 700;
}

.medium {
    font-weight: 500;
}

/* RADIUS */
.radius {
    border-radius: var(--space-2);
}

.link-div {
    display: flex;
    align-items: center;
}

/* 
.p-1 {
    padding: var(--space-1);
}

.p-2 {
    padding: var(--space-2);
}

.p-3 {
    padding: var(--space-3);
}

.p-4 {
    padding: var(--space-4);
}

.px-1 {
    padding: 0 var(--space-1);
}

.px-2 {
    padding: 0 var(--space-2);
}

.px-3 {
    padding: 0 var(--space-3);
}

.px-4 {
    padding: 0 var(--space-4);
}

.py-1 {
    padding: var(--space-1) 0;
}

.py-2 {
    padding: var(--space-2) 0;
}

.py-3 {
    padding: var(--space-3) 0;
}

.py-4 {
    padding: var(--space-4) 0;
}



.pt-1 {
    padding-top: var(--space-1);
}

.pt-2 {
    padding-top: var(--space-2);
}

.mt-2 {
    margin-top: var(--space-2);
}

.mt-3 {
    margin-top: var(--space-3);
}

.mx-1 {
    margin: 0 var(--space-1);
}

.ml-1 {
    margin-left: var(--space-1);
}

.ml-2 {
    margin-left: var(--space-2);
}

.ml-3 {
    margin-left: var(--space-3);
} */

.info {
    position: relative;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.pagination li:first-child,
.pagination li:last-child,
.page-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination li:first-child {
    margin-right: var(--space-2);
}

.pagination li:last-child {
    margin-left: var(--space-2);
}

.page-item {
    width: 47px;
    height: 47px;
    background-color: var(--secondary-color);
    color: black;
    border-radius: var(--space-2);
    margin-left: var(--space-1);
}

.active {
    background-color: var(--primary-color);
    color: var(--secondary-color)
}

.disabled {
    cursor: not-allowed !important;
    opacity: .6;
}

ul li:first-child a div {
    display: flex;
    align-items: center;
    gap: var(--space-1);
}

.btn {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    width: 100%;
    cursor: pointer;
    border-radius: var(--space-2);
}

.btn-primary {
    background-color: var(--primary-color) !important;
    color: var(--light-color) !important;
}

.btn-outline {
    background-color: rgba(41, 72, 141, .15) !important;
    color: var(--primary-color) !important;
}

.base-input {
    border: none;
    background-color: var(--gray-color2);
    border-radius: var(--space-2);
    padding: 13px;
    font-size: 16px;
}

.flex-column {
    flex-direction: column;
}

/* .flex {
    display: flex;
} */
.form-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-item div {
    width: 40%;
}

.width100 {
    width: 100% !important;
}

.width50 {
    width: 50% !important;
}

.small-gallery .second-swiper-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* .second-swiper-container {
    width: 100%;
    height: 100%;
} */

/* .small-image {
   height: 10vh;
} */



.main-params {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-direction: column;
}

.single-param {
    display: flex;
    justify-content: space-between;
}

.single-param h3 {
    width: 50%;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--space-2);
}

.grid-item {
    text-align: center;
}

.grid-item img {
    max-height: 80%;
    max-width: 80%;
}

.grid-image {
    background-color: var(--gray-color);
    border-radius: var(--space-2);
    width: 128px;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--space-2);

}

.blog-item {
    text-align: center;
}

.blog-image {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.blog-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.show {
    display: block;
}

.language {
    cursor: pointer;
}

.language:hover {
    background-color: #ddd;
}

.addresses {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.collapsible {
    width: 382px;
    height: 100%;
    background-color: var(--gray-color);
    cursor: pointer;
    transition: 3s;
}

.contact-card {
    border-radius: 15px;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.contact-inform {
    align-items: center;
    display: flex;
}

.collapsible .contact-card .contact-image {
    width: 90px;
    height: 90px;
    padding: var(--space-2);
    background-color: var(--white-color);
}

.contact-image img {
    width: 100%;
    height: 100%;
}

.leaflet-container {

    border-radius: var(--space-2) !important;
}

.leaflet {
    padding: var(--space-2);
}

.collapsible .contact-content {
    overflow: hidden;
    border-radius: var(--space-2);
    background-color: #fff;
    animation: growDown 700ms ease-in-out;
    transform-origin: top center
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }

    80% {
        transform: scaleY(1.1)
    }

    100% {
        transform: scaleY(1)
    }
}

.collapsible .seperator {
    width: 100%;
    height: 2px;
    background: #fff;
}

.shop-container {
    background-color: var(--gray-color);
    border-radius: var(--space-2);
    margin-bottom: var(--space-2);
    padding: var(--space-2);
}

.shop-container img {
    width: 100%;
    height: 100%;
}

.contact-phone {
    align-items: center;
    display: flex;
}

.bg-light {
    background-color: #fff;
}

.pointer {
    cursor: pointer;
}


section {
    padding-top: 15px;
    padding-bottom: 30px;
}

.price-infos {
    display: flex;
    /* flex-direction: column;
    justify-content: center; */
}

.footer section {
    margin-right: 15px;
}

.item-card:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.item-card:hover>.bottom-infos .price-infos .button-secondary-outline {
    background-color: var(--primary-color);
    width: 96px;
    color: white;
}

.item-card .extra-infos {
    position: absolute;
    top: var(--space-2);
    left: var(--space-2);
}



.item-card img {
    border-radius: var(--space-2);
    max-width: 100%;
}

.item-card .small-infos {
    padding: 0 var(--space-2);
}

.item-card .price-infos {
    display: flex;
    padding: var(--space-1) var(--space-2);
    justify-content: space-between;
    align-items: center;
}

.base-button {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 13px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-2);
    width: 171px;
    cursor: pointer;
    border-radius: var(--space-2);
}

.button-primary {
    background-color: var(--primary-color);
    color: var(--light-color);
}

.button-secondary {
    background-color: #D6DCE8;
    color: var(--primary-color);
}

.button-secondary-outline {
    background-color: rgba(41, 72, 141, .15);
    color: var(--primary-color);
    font-size: 13px;
    font-family: 'Rubik' sans-serif;
    font-weight: 500;
    width: 96px;
}

.button-primary-outline {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    transition: .37s;
    font-size: 13px;
    font-family: 'Rubik' sans-serif;
    font-weight: 500;
}

.button-primary-outline:hover {
    background-color: var(--primary-color);
    color: white;

}

.mobile-appear,
.mobile-appear * {
    display: none;
}

.icon {
    font-size: 16px;
    width: 18px;
}

.open-content .icon {
    margin-right: 15px;
}