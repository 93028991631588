/*
* Prefixed by:
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/* @media (max-width:1440px) {
    .collections{
        grid-template-columns: repeat(3, 1fr);
    }
} */
@media (max-width:1200px) {
    .collections-small {
        -ms-grid-columns: repeat(2,1fr);
        grid-template-columns: repeat(2, 1fr);
    }

    .item-card {
        /* width: 240px; */
        max-height: 560px;
    }

    .grid-container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr var(--space-2) 1fr var(--space-2) 1fr;
        grid-template-columns: repeat(3, 1fr);
        gap: var(--space-2);
    }
}

@media (max-width:992px) {
    .price-infos {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .collections {
        -ms-grid-columns: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);
    }

    .basket {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .basket-left,
    .basket-right {
        width: 100%;
    }

    .product-info .upper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .product-info .gallery,
    .product-info .options {
        width: 100%;
    }

    .upper-gallery {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .parent-content {
        width: 100%;
    }

    .grid-container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr var(--space-2) 1fr var(--space-2) 1fr var(--space-2) 1fr;
        grid-template-columns: repeat(4, 1fr);
        gap: var(--space-2);
    }

    .gallery {
        height: auto;
    }

    .options {
        height: auto;
    }

    .modal-elements {
        gap: 5px
    }

    .modalContent {
        width: 90%;
    }

    .item-info {
        width: auto;
    }

    .item-info img {
        width: 50%;
    }
}

@media (max-width:768px) {
    .collections-small {
        -ms-grid-columns: repeat(1,1fr);
        grid-template-columns: repeat(1, 1fr);
    }

    .small-gallery {
        display: none;
    }

    .grid-container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr var(--space-2) 1fr;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--space-2);
    }

    .grid-item {
        width: 128px;
    }

    .modalContent {
        width: 90%;
    }



}

@media (max-width:576px) {

    .mobile-appear,
    .mobile-appear * {
        display: initial;
    }

    .mobile-hidden {
        display: none !important;
    }

    .collections-small,
    .collections {
        -ms-grid-columns: repeat(1, 1fr) !important;
        grid-template-columns: repeat(1, 1fr) !important;
        margin: 0 auto;
    }

    /* .filters-section{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        top: 0;
        left: 0;
        z-index: 60000;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    } */
    .filters-box {
        position: relative;
        background-color: white;
        padding: 15px;
        border-radius: 15px 15px 0 0;

    }

    .scrol-filters {
        max-height: 60vh;
        overflow-y: scroll;
    }

    .filters-container {
        display: none;
    }

    .filter-close {
        width: 100%;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        top: 0;
        left: 0;
        z-index: 60000;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    .added-items {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }

    .single-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
    }

    .single_cart_item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
    }

    .form-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .area {
        width: 100% !important;
        margin-left: 0;
        margin-right: 0;
    }
    .regions-container {
        max-height: 30vh;
        overflow: scroll;
    }

}
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    /* background-color: transparent; */
    border: none;
    font-size: 20px;
    cursor: pointer;
  }