/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

a {
    color: inherit;
}

p {
    margin-bottom: 0 !important;
}

label {
    margin-bottom: 0 !important;
}

.parent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}


header div section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: var(--space-2) 0;
}

header div section .left-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: var(--space-1)
}

header div section .right-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: var(--space-2);
    margin-left: var(--space-2);
    color: var(--text-color);
}

.cart {
    position: relative;
}

.basket-badge {
    background-color: var(--danger-color);
    color: var(--light-color) !important;
    width: 15px;
    height: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
    padding: 0.5em;
}

.vertical-line {
    border-left: 1px solid var(--text-color);
    height: 20px;
}

.horizontal-line {
    height: 2px;
    background-color: black;
    width: 100%;
}

.carousel {
    width: 100%;
    height: 52%;
    border-radius: var(--space-2);
    margin: 0 auto;
    position: relative;
}

.banner {
    border-radius: var(--space-2);
}

.carousel img {
    width: 100%;
    height: 100%;
    border-radius: var(--space-2);
}



.category-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr var(--space-3) 1fr;
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
    overflow: hidden;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: var(--space-3);
}

.category-card {
    width: 100%;
    height: 100%;
    position: relative;
}

.category-card>img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: var(--space-2);
}

.category-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: var(--space-2);
    background: -o-linear-gradient(337.32deg, rgba(0, 4, 33, .5), rgba(0, 4, 33, .13));
    background: linear-gradient(.313turn, rgba(0, 4, 33, .5), rgba(0, 4, 33, .13));
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: var(--space-2);
}

.category-text {
    font-size: 18px;
    line-height: 27px;
    color: var(--light-color);
    font-weight: bold;
}

.icon-container {
    border: 1px solid #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: var(--space-2);
    padding: 10px;
}

.trend-products-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* padding-top: var(--space-2); */
    /* padding-bottom: var(--space-2); */
}

.trend-products {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: var(--space-3);
    padding-top: var(--space-2);
}

.item-card {
    background-color: #fff;
    position: relative;
    width: 279px;
    min-height: 490px;
    border-radius: var(--space-2);
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}
.item-card-skeleton {
    background-color: #fff;
    position: relative;
    /* width: 279px; */
    min-height: 490px;
    border-radius: var(--space-2);
}
.card-image-skeleton {
    margin: 0 10px;
}
/* .image-skeleton {
    position: relative;
    height: 370px;
    width: 100%;
    padding-top: 0;
    margin: 0 5px 10px 2px;
    border-radius: var(--space-2);
} */
.small-infos-skeleton {
    margin: 15px 0;
    height: 30px;
}

.bottom-skeleton {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 15px 10px;
}
.bottom-skeleton .left-skeleton {
    margin-right: var(--space-3);
    margin-left: 0;
}

.bottom-skeleton .right-skeleton {
    flex: 1;
}



.secondary-button {
    background-color: rgba(41, 72, 141, .15);
    color: var(--primary-color);
    padding: var(--space-2) var(--space-3);
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: var(--space-2);
}

.secondary-button:hover {
    background-color: var(--primary-color);
    color: var(--light-color);
}


.bold {
    font-weight: 700;
}

.mt-1 {
    margin-top: var(--space-1);
}


.features {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: var(--space-3);
}

.feature-text {
    width: 100%;
}

.feature-card {
    width: 100%;
    position: relative;
    padding-top: 100%;
    background-color: #fff;
    border-radius: var(--space-2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 30%;
}


.feature-card img {
    width: 100%;
    height: 100%;
    border-radius: var(--space-2);
    top: 0;
}

.blog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: var(--space-1);
}

input[type=search] {
    cursor: pointer;
    width: 20px;
    color: var(--text-color);
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    outline: none;
    border: none;
    padding: 10px;
    border-radius: var(--space-2) var(--space-2);
    background: var(--gray-color2) url(../../../public/search.svg) no-repeat 9px;
    background-color: var(--gray-color2);
    font-size: 16px;
    line-height: 24px;
    margin-left: 15px;
    position: relative;
}

input[type=search]::-webkit-input-placeholder {
    opacity: 0;
}

input[type=search]::-moz-placeholder {
    opacity: 0;
}

input[type=search]:-ms-input-placeholder {
    opacity: 0;
}

input[type=search]::-ms-input-placeholder {
    opacity: 0;
}

input[type=search]::placeholder {
    opacity: 0;
}

input[type=search]::before {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    left: 10px;
    top: 50%;
    background-size: contain;
}

input[type=search]:focus {
    width: 100%;
    border: none;
    border-radius: var(--space-2) var(--space-2);
    font-size: 16px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding-left: 40px;
}

.focus {
    width: 100% !important;
    padding-left: 40px !important;
}

input[type=search]:focus::-webkit-input-placeholder {
    opacity: 1;
    font-size: small;
    color: rgb(87, 79, 79)
}

input[type=search]:focus::-moz-placeholder {
    opacity: 1;
    font-size: small;
    color: rgb(87, 79, 79)
}

input[type=search]:focus:-ms-input-placeholder {
    opacity: 1;
    font-size: small;
    color: rgb(87, 79, 79)
}

input[type=search]:focus::-ms-input-placeholder {
    opacity: 1;
    font-size: small;
    color: rgb(87, 79, 79)
}

input[type=search]:focus::placeholder {
    opacity: 1;
    font-size: small;
    color: rgb(87, 79, 79)
}


.fa-arrow-right {
    color: #fff;
}

.blogs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: var(--space-3);
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
}

/* .mySwiper .blogs {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    gap: 30px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
} */

.blog-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: var(--space-1);
    background-color: #fff;
    border-radius: var(--space-2);
}

.allBlogs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: var(--space-1);
    background-color: #fff;
    width: 48%;
    height: 253px;
    border-radius: var(--space-2);
}

.blog-image img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 45%;
    height: 100%;
    border-radius: var(--space-2);
}

.blog-info {
    margin-left: var(--space-2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
}

.blog-info h1 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 100%;
    height: 70px;
    padding-top: var(--space-1);
}

.blog-info p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 18px;
    line-height: 27px;
    color: var(--text-color);
}

/* .blog-info p, h1, h2, h3, h4, h5, h6, strong {
    font-weight: normal ;
} */

.blog-date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: var(--space-2);
    margin-top: var(--space-4);
    color: var(--text-color);
}

footer .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: var(--space-2);
    gap: var(--space-3);
}

ul {
    margin-top: var(--space-2);

}

ul li {
    list-style: none;
    color: var(--text-color);
}

.collections {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr var(--space-3) 1fr var(--space-3) 1fr var(--space-3) 1fr;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--space-3);
}

.collections-small {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr var(--space-3) 1fr var(--space-3) 1fr;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--space-3);
}

.collections-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: var(--space-2);
}

.filters-container {
    width: 24%;
}

.filter-close {
    display: none;
}

.filter-title {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.basket {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: var(--space-3);
}

.basket-left {
    min-height: 30vh;
    background-color: var(--light-color);
    border-radius: var(--space-2);
    width: 65%;
}

.basket-right {
    min-height: 20vh;
    background-color: var(--light-color);
    border-radius: var(--space-2);
    width: 35%;
}

.upper-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.item-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: var(--space-2);
}
.single_card_item {
    background-color: var(--gray-color);
        border-radius: var(--space-2);
        width: 100%;
}

.single-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    /* min-height: 20vh; */
    background-color: var(--gray-color);
    border-radius: var(--space-2);
    width: 100%;
}

.single_cart_item {
    height: 222px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
}

.single_cart_item img {
    height: 100%;
    border-radius: var(--space-2);
}

.single-item {
    height: 222px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
}

.single-item img {
    height: 100%;
    border-radius: var(--space-2);
}

.item_cart_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

.item-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 50%;
}

.cancel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: var(--space-2);
}

.item-button {
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: var(--space-2);
}

.less-more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: var(--space-2);
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.price-config {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.check-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.check-info:not(:first-child) {
    margin-top: var(--space-3);
}

.check-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: var(--space-3);
}

.link {
    text-decoration: underline;
}

.parent-content {
    width: 50%;
}

.upper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: var(--space-2);
    padding: 5px;
}

.upper-gallery {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    gap: var(--space-3);
    ;
}


.gallery {
    width: 50%;
    height: 80vh;
}

.options {
    width: 50%;
    height: 70vh;
}

.options>p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.big-gallery {
    height: 65vh;
    width: 100%;
    padding: 1px;
}

.gallery-image {
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    height: 60vh;
    margin-top: 15px;
}


.small-gallery {
    position: relative;
    width: 100%;
    padding: 1px;
    height: 20vh;
    overflow: hidden;
}

.small-gallery::before {
    content: "";
    background-image: url(/public/banner.81139ad.png);
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.1;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.small-gallery .second-swiper-container,
.small-gallery .swiper-slide {
    width: 100%;
    height: 65%;
}


.small-gallery .swiper-slide img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.tabs {
    border-radius: 6px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    list-style: none;
    background-color: var(--gray-color);
    border-radius: 0 0 var(--space-2) var(--space-2) !important
}

.tab {
    display: none
}

.tab:checked:first-of-type~.tab-content:first-of-type,
.tab:checked:nth-of-type(2)~.tab-content:nth-of-type(2),
.tab:checked:nth-of-type(3)~.tab-content:nth-of-type(3) {
    opacity: 1;
    -webkit-transition: opacity .5s ease-in, -webkit-transform .2s ease;
    transition: opacity .5s ease-in, -webkit-transform .2s ease;
    -o-transition: opacity .5s ease-in, transform .2s ease;
    transition: opacity .5s ease-in, transform .2s ease;
    transition: opacity .5s ease-in, transform .2s ease, -webkit-transform .2s ease;
    position: relative;
    top: 0;
    z-index: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    text-shadow: 0 0 0
}

.tab:checked:nth-of-type(4)~.tab__content:nth-of-type(4) {
    opacity: 1;
    -webkit-transition: opacity .5s ease-in, -webkit-transform .2s ease;
    transition: opacity .5s ease-in, -webkit-transform .2s ease;
    -o-transition: opacity .5s ease-in, transform .2s ease;
    transition: opacity .5s ease-in, transform .2s ease;
    transition: opacity .5s ease-in, transform .2s ease, -webkit-transform .2s ease;
    position: relative;
    top: 0;
    z-index: 100;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    text-shadow: 0 0 0
}

.tab:first-of-type:not(:last-of-type)+label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.tab:not(:first-of-type):not(:last-of-type)+label {
    border-radius: 0;
}

.tab:last-of-type:not(:first-of-type)+label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.tab:checked+label {
    background-color: var(--light-color);
    color: var(--text-color);
    font-size: 18px;
    font-weight: 700;
    border-radius: var(--space-2) var(--space-2) 0 0 !important;
    cursor: default;
    font-family: "rubik-bold", sans-serif !important;
}

.tab+label {
    width: 100%;
    cursor: pointer;
    display: block;
    text-decoration: none;
    font-family: "rubik", sans-serif !important;
    font-size: 16px;
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    height: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 15px;
}

@media (min-width: 768px) {
    .tab+label {
        width: auto;
    }
}

.tab-content {
    padding: 10px;
    background-color: var(--light-color);
    position: absolute;
    width: 100%;
    z-index: -1;
    opacity: 0;
    left: 0;
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
    border-radius: 0 0 var(--space-2) var(--space-2);
    font-family: "rubik", sans-serif !important;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
    overflow-x: scroll;

}

.size-container {
    max-height: 55vh;
    overflow-y: scroll;
}

.size-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.size-header>span {
    width: 33%;
}

.size-content {
    max-height: 520px;
    overflow: auto;
}

.checkbox-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: var(--space-2);
    margin-bottom: var(--space-1) !important;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border: 2px solid var(--text-color);
}

.checktitle {
    position: relative;
    padding-left: var(--space-2);
    bottom: 4px;
}

.checkbox-container input:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 4px;
    top: 2px;
    width: 2px;
    height: 3px;
    border: solid #29488d;
    border: solid var(--primary-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checktitle {
    font-weight: 500;
    color: var(--primary-color);
}

.size-content-info {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: var(--space-1);
    /* align-items: center; */
}

.size-content-info>span {
    width: 33%;
}


.content {
    padding-top: var(--space-2);
    display: none;

}

.show-content .content {
    display: block;

}



.input-container {
    border-radius: var(--space-2);
    border: 1px solid var(--gray-color);
    padding: 13px;
    font-size: 16px;
    background-color: var(--gray-color2);
    width: 100%;
    cursor: text;
}

.input-container:focus {
    outline: none;
}

.unit {
    font-size: 16px;
    color: var(--text-color);
    margin-left: var(--space-1);
    position: absolute;
    top: 50%;
    right: 20px;
}

.filter-fields {
    max-height: 200px;
    overflow: auto;
}

.mod {
    display: none;
    position: fixed;
    z-index: 6000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-transition: 2s;
    -o-transition: 2s;
    transition: 2s;
    background: rgba(0, 4, 33, .8);
}

.modalOpen {
    display: block;
}

.survery-container{
    height: 80vh;
    overflow: scroll;
}

.modalContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border-radius: var(--space-2);
    width: 60%;

}

.cart-scrol {
    max-height: 60vh;
    overflow-y: scroll;
}

.regions-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: var(--space-2);
}

.region {
    background-color: var(--gray-color);
    color: var(--text-color);
    padding: var(--space-2) var(--space-3);
    cursor: pointer;
}

.added-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.added-items img {
    width: 75px;
    height: 90px;
    margin-right: var(--space-2);
    border-radius: var(--space-2);
}

.added-item-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.modal-elements {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 50px;
}

.modal-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: var(--space-2);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.modal-buttons button {
    width: 221px;
}

.swip {
    width: 45vw;
}

.colors {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    bottom: 1.2em;
    width: 100%;
}

.colors .single-color:first-child {
    right: 0;
    z-index: 5;
}

.colors .single-color:nth-child(2) {
    right: 0.8em;
    z-index: 4;
}

.colors .single-color {
    width: 16px;
    height: 16px;
    margin-right: 0.1em;
    border-radius: 50%;
    position: absolute;
}

/* .survey-container {
    max-height: 50vh;
    overflow-y: scroll;
} */

.single-survey {
    background-color: var(--gray-color);
    border-radius: var(--space-2);
    padding: var(--space-2);
    margin-top: var(--space-2);
}

.single-survey .survey-title {
    text-align: center;
}

.single-survey .option-container {
    margin-top: var(--space-2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.single-survey .option-container .single-option {
    margin-right: var(--space-3);
}

.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.radiomark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #394b5c;
    border: 2px solid var(--text-color);
}

.radiomark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-container input:checked~.radiomark:after {
    display: block;
}

.radio-container .radiomark:after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #394b5c;
    background-color: var(--text-color);
}

.radiomark:after {
    content: "";
    position: absolute;
    display: none;
}

.text-input {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    color: #000421;
    font-size: 16px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    -webkit-box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    z-index: 2;
}

.bar-menu {
    display: none;
}

.elements {

    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    overflow: hidden;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation: growRight 700ms ease-in-out;
    animation: growRight 700ms ease-in-out;
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right
}

@-webkit-keyframes growRight {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }

    100% {
        -webkit-transform: scaleX(100%);
        transform: scaleX(100%)
    }
}

@keyframes growRight {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }

    100% {
        -webkit-transform: scaleX(100%);
        transform: scaleX(100%)
    }
}


.open-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 85%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: large;
}

.close-icon {
    position: absolute;
    top: 1em;
    right: 1em;
    display: block !important;
    margin: 0 !important;
}

/* .swiper-slide {
    flex-shrink: auto !important;
    height: auto !important;
} */

.checkmark,
::after,
::before {
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important;
}

@media only screen and (max-width: 768px) {

    .bar-menu {
        display: inline;
        margin-right: var(--space-2);
        display: block;
    }

    .cart,
    .dropdown,
    .location,
    .services,
    .vertical-line {
        display: none;
    }

    .search-input {
        margin-left: 0;
    }

    .basket-badge {
        position: relative;
        right: 0;
    }

    input[type=search]:focus {
        width: 40%;
    }

    .focus {
        width: 40%;
    }

    .category-container {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }

    footer .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        /* padding: var(--space-3); */
    }

    .blog-image {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: auto;
    }

    .blog-image img {
        height: auto;
        width: 100%;
    }

    .swiper-slide {
        -ms-flex-negative: auto !important;
        flex-shrink: auto !important;
        height: auto !important;
    }

    .blog-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .trend-products {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .allBlogs {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
        width: 100%;
    }

}

.swiper {
    padding-bottom: 10px !important;
}

.two-line-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.left {
    margin-left: 0.5rem;
}

.right {
    margin-right: 1rem;
}

.orderinfo {
    background-color: var(--light-color);
    border-radius: var(--space-2);
}

.item-info img {
    width: 200px;
    border-radius: var(--space-2);
    margin-right: 1rem;
}